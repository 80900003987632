/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const ROUTES = {
  BASE: "/",
  INDEX: "/index.html",
  FAQ: "/faq",
  READ_MORE_SCREEN: "/read-more",
  MAIN_SCREEN: "/",
  HOME: "/home",
  MOVIE_DETAILS_SCREEN: "/movie-details",
  PODCAST_DETAILS_SCREEN: "/podcast-details",
  ALBUM_DETAILS_SCREEN: "/album-details",
  ARTICLE_SCREEN: "/article",
  SERIES_DETAILS_SCREEN: "/series-details",
  PACKAGE_DETAILS_SCREEN: "/package-details",
  LIVE_DETAILS_SCREEN: "/live-details",
  PLAYER_SCREEN: "/player",
  BUY_SCREEN: "/buy",
  PAYMENT_STATUS_SCREEN: "/payment-status",
  PAYMENT_CHANGE_STATUS_SCREEN: "/payment-change-status",
  PLAYLIST_SCREEN: "/playlist",
  SEARCH_SCREEN: "/search",
  MY_LIST_SCREEN: "/my-list",
  SETTINGS_SCREEN: "/settings",
  SETTINGS_ABOUT_SCREEN: "/settings/about",
  SETTINGS_MY_CONSENTS_SCREEN: "/settings/my-consents",
  SETTINGS_REGULATIONS_SCREEN: "/settings/regulations",
  SETTINGS_PRIVACY_POLICY_SCREEN: "/settings/privacy-policy",
  SETTINGS_LANGUAGE_SCREEN: "/settings/language",
  SETTINGS_TERMS_AND_CONDITIONS: "settings/terms_and_conditions",
  PRIVACY_POLICY_SCREEN: "/privacy-policy",
  SETTINGS_MY_ORDERS: "/settings/my-orders",
  SETTINGS_HELP_SCREEN: "/settings/help",
  SETTINGS_PROFILE_SCREEN: "/settings/profile",
  DELETE_ACCOUNT: "/delete-account",
  DELETE_ACCOUNT_SUCCESS: "/delete-account-success",
  EVENTS: "/events",
  MEDIA_CREATOR: "/media-creator",
  MYLIST_SCREEN: "/my-list",
  EPG_SCREEN: "/epg",
  CATEGORY_SCREEN: "/category",
  CATEGORY_DETAILS_SCREEN: "/category-details",
  LINK_SCREEN: "/link",
  LOGIN: "/login",
  LOGIN_CONFIRM_EMAIL: "/login-confirm-email",
  LOGIN_INVALID_DATA: "/login-invalid-data",
  REGISTER: "/register",
  REGISTER_SUCCESS: "/register-success",
  REGISTER_CONFIRM: "/confirmEmail",
  RESEND_CONFIRMATION_SUCCESS: "/resend-confirmation-success",
  REGISTER_CONFIRM_ACCOUNT_WITH_PASSWORD: "/confirmAccountWithPassword",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/forgot-password-success",
  RESET_PASSWORD: "/resetPassword",
  AUTH_CONFIRM_EMAIL: "/auth/confirmAccount",
  AUTH_RESET_PASSWORD: "/auth/resetPassword",
  CHANNEL_DETAILS_SCREEN: "/channel",
  PROFILE_DETAILS_SCREEN: "/profile",
  EDIT_PERSONAL_DATA: "edit_personal_data",
  EDIT_FAVORITE_CONTENTS: "edit_favorite_contents",
  CUSTOM_PRIVACY_POLICY_SCREEN: "/home_politicaprivacitat",
  CUSTOM_TERMS_OF_USE_SCREEN: "/home_termesus",
};
